html {
    overflow-y: scroll;
}

#topSearchBar > form {
    float: right;
}
#content-area {
    background-color: #FFF;
    padding: 10px 10px 20px;
}

@media (max-width: 345px) {
    #content-area {
        background-color: #FFF;
        padding: 25px 0px 0px 0px;
        width: 100%;
    }
}
#footer-area {
    background-color: #FFF;
}
.footer-column h1 {
    text-align:left;
    text-transform: uppercase;
    color:#046cc1;
    margin:0 0 5px 0;
}
.footer-column a {
    color: #151515;
    font-weight: 600;
    display:block;
    text-align:left;
    font-size: 14px;
}
.footer-column a:hover {
    color: #046cc1;
}
.footer-column a.footer_social_media {
    display: inline-block;
    margin-right: 5px;
}
.barcode {font-size:13px;
          text-align:center;
          font-family:'Free 3 of 9 Extended Regular'; 
}
div {
    margin: 0px;
}

.checkofut div {
    margin: 0 auto;
}
input[type=submit]#search_but{
	background-image: url('/images/search-button.jpg');
	border:none;
	height:25px;
	width: 108px;
	text-transform: uppercase;
	font-family:'Open Sans',Arial,sans-serif;
	font-weight:600;
	padding:0;
	margin:0;
	-moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
}